import { Grid } from "@mantine/core";
import moment from "moment";
import React from "react";

/// <summary>
/// Author: JavaChips
/// </summary>
const Footer = () => {
	return (
		<>
			<div id="footer">
				<Grid className="desc-footer">
					<Grid.Col xs={12} lg={6} className="desc">
						<div>
							<h3 className="title">George Town Literary Festival</h3>
							<p className="text">
								The George Town Literary Festival is an international literary festival celebrating world literature, translations and literary arts. Held annually in the UNESCO World Heritage site of George Town, Penang, the festival was awarded ‘The International Excellence Awards 2018 for Literary Festival’.
								<br />
								<br />
								The GTLF is an initiative by the State Government of Penang and remains one of the few free festivals open to the public. Since its first edition in 2011 with only five writers, the GTLF has grown to become Malaysia’s largest literary festival and one of the best literary festivals in the world.
							</p>
						</div>
					</Grid.Col>
					<Grid.Col xs={4} lg={3} className="logo-container">
						<div>
							<img src={require("../assets/images/penang-institute.png")} alt="Penang Institute" />
						</div>
					</Grid.Col>
					<Grid.Col xs={4} lg={3} className="logo-container">
						<div>
							<img src={require("../assets/images/penang-monthly.png")} alt="Penang Monthly" />
						</div>
					</Grid.Col>
				</Grid>
				<div className="copyright-footer">
					<div className="text">Copyright &copy; {moment().format("YYYY")} George Town Literary Festival</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
