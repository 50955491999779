import { useEffect, useState } from "react";
import { Box, Center, Grid, Modal, Text } from "@mantine/core";
import supabase from "../../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function OpenCall() {
	const [openCall, setOpenCall] = useState(null);
	const [magnifyImage, setMagnifyImage] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		document.title = `#GTLF2023 | Open Call`;

		(async () => {
			const { data, error } = await supabase
				.from("open-call")
				.select(
					`
            content,
            open-call-sections(
              image,
              content
            )
          `
				)
				.limit(1)
				.single();

			if (error !== null) {
				throw error.message;
			}

			setOpenCall(data);
		})();
	}, []);

	useEffect(() => {
		setModalVisible(magnifyImage !== null);
	}, [magnifyImage]);

	return (
		<div id="gtlf">
			<div className="curatorial-title" style={{ marginTop: 0 }}>
				<h2>Open Call</h2>
			</div>
			{openCall === null && (
				<Center style={{ height: 400 }}>
					<Text color="black">Open Call is empty for now. Please check back at a later time!</Text>
				</Center>
			)}
			{openCall !== null && (
				<>
					<Box sx={() => ({ marginTop: 50 })}>
						<div dangerouslySetInnerHTML={{ __html: openCall?.content }} />
					</Box>
					{openCall["open-call-sections"].map((item) => (
						<Box sx={() => ({ marginTop: 50 })}>
							<Center>
								<img style={{ borderRadius: 10, width: 300, height: 300 }} src={item.image} alt="Section Image" onClick={() => setMagnifyImage(item.image)} />
							</Center>
							<div dangerouslySetInnerHTML={{ __html: item?.content }} />
						</Box>
					))}
				</>
			)}
			<Modal opened={modalVisible} onClose={() => setMagnifyImage(null)} title="" size="lg">
				<img style={{ borderRadius: 10, width: "100%", aspectRatio: 1 }} src={magnifyImage} alt="Modal Image" />
			</Modal>
		</div>
	);
}

export default OpenCall;
