import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SocialMedia from "./components/SocialMedia";
import useAppStore from "./hooks/zustand/useAppStore";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import supabase from "./utils/Supabase";

dayjs.extend(utc);
dayjs.extend(timezone);

/// <summary>
/// Author: JavaChips
/// </summary>
function App() {
	const updateGtlfState = useAppStore((state) => state.updateGtlf);

	useEffect(() => {
		document.title = "#GTLF2023";

		getActiveGtlf();
	}, []);

	const getActiveGtlf = async () => {
		const { data, error } = await supabase
			.from("gtlf")
			.select(
				`
            *,
            curatorial-team (image, name, desc, position),
            line-ups (image, name, desc),
            festival-programmes (
                *,
                programme-days (date),
                event-buildings (name, index)
            ),
            happening-simultaneously (image, link, desc)
        `
			)
			.eq("status", true)
			.order("id", { ascending: false })
			.limit(1);

		if (error !== null) {
			throw error.message;
		}

		if (data.length === 0) {
			return;
		}

		const { "curatorial-team": curatorialTeam, "festival-programmes": festivalProgrammes, "happening-simultaneously": happeningSimultaneously, "line-ups": lineUps, ...rest } = data[0];

		let flattenProgrammes = festivalProgrammes.map(({ "event-buildings": eventBuilding, "programme-days": programmeDay, ...rest }) => {
			return {
				...rest,
				locationName: eventBuilding.name,
				locationSort: eventBuilding.index,
				dayDate: dayjs.utc(programmeDay.date).tz("Asia/Kuala_Lumpur").format("DD MMMM YYYY, dddd"),
			};
		});

		let sortByDay = {};
		let uniqueDays = [...new Set(flattenProgrammes.map((item) => item.dayDate))];

		uniqueDays.map((day) => {
			let sameDays = flattenProgrammes.filter((item) => item.dayDate === day);

			sortByDay[day] = sameDays;
		});

		Object.keys(sortByDay).map((key) => {
			let copyOfArray = [...sortByDay[key]];

			sortByDay[key] = [];

			let uniqueBuildings = [
				...new Set(
					copyOfArray.map((item) => ({
						building: item.locationName,
						sort: item.locationSort,
					}))
				),
			];

			uniqueBuildings.map(({ building, sort }) => {
				let sameBuildings = copyOfArray.filter((item) => item.locationName === building);

				let isEmpty = sortByDay[key].filter((item) => item.locationName === building).length === 0;

				if (!isEmpty) {
					return;
				}

				sortByDay[key].push({
					locationName: building,
					locationSort: sort,
					programmes: sameBuildings.sort((p1, p2) => (p1.index < p2.index ? -1 : 0)),
				});

				sortByDay[key].sort((p1, p2) => (p1.locationSort < p2.locationSort ? -1 : 0));
			});
		});

		let cleanedObj = {
			...rest,
			curatorialTeam,
			lineUps,
			happeningSimultaneously,
			festivalProgrammes: sortByDay,
		};

		document.title = `#GTLF${cleanedObj.year} | ${dayjs.utc(cleanedObj.start_date).tz("Asia/Kuala_Lumpur").format("DD")} - ${dayjs.utc(cleanedObj.end_date).tz("Asia/Kuala_Lumpur").format("DD MMMM")} ${cleanedObj.year}`;

		updateGtlfState(cleanedObj);
	};

	return (
		<div className="App">
			<Header />
			<Outlet />
			<SocialMedia />
			<Footer />
		</div>
	);
}

export default App;
