import moment from "moment";
import { useEffect, useState } from "react";

/// <summary>
/// Author: JavaChips
/// </summary>
const useCountdown = (targetDate) => {
	const [countdown, setCountdown] = useState(getValues(targetDate));

	useEffect(() => {
		const interval = setInterval(() => {
			setCountdown(getValues(targetDate));
		}, 1000);

		return () => clearInterval(interval);
	}, [targetDate]);

	return countdown;
};

const getValues = (targetDate) => {
	const currDate = moment();
	const countDownDate = moment.duration(moment(targetDate.diff(currDate)));

	const months = countDownDate.months().toString().padStart(2, 0);
	const days = countDownDate.days().toString().padStart(2, 0);
	const hours = countDownDate.hours().toString().padStart(2, 0);
	const minutes = countDownDate.minutes().toString().padStart(2, 0);
	const seconds = countDownDate.seconds().toString().padStart(2, 0);

	return [months, days, hours, minutes, seconds];
};

export { useCountdown };
