import { useEffect } from "react";
import { Link } from "react-router-dom";
import WebRoutes from "../utils/WebRoutes";

import { Header, Menu } from "@mantine/core";
import moment from "moment";
import useAppStore from "../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
function Navbar({ year }) {
	const gtlfState = useAppStore((state) => state.gtlf);

	const _MENU = [
		{
			label: "The Festival",
			link: "#",
			links: [
				{
					label: "About Us",
					link: WebRoutes.ABOUT_US,
				},
				{
					label: "Muara",
					link: WebRoutes.MUARA,
				},
				{
					label: "Muara Writing Prize",
					link: WebRoutes.MUARA_WRITING_PRIZE,
				},
				{
					label: "Past Festivals",
					link: WebRoutes.PAST_FESTIVALS,
				},
			],
		},
		{
			label: "GTLF " + (gtlfState?.year || moment().format("YYYY")),
			link: "#",
			links: [
				{
					label: gtlfState?.alias || `GTLF ${moment().format("YYYY")}`,
					link: WebRoutes.GTLF,
				},
				{
					label: "Line-Ups",
					link: WebRoutes.LINE_UPS,
				},
				{
					label: "Festival Programme",
					link: WebRoutes.FESTIVAL_PROGRAMME,
				},
				{
					label: "Happening Simultaneously",
					link: WebRoutes.HAPPENING_SIMULTANEOUSLY,
				},
				{
					label: "Open Call",
					link: WebRoutes.OPEN_CALL,
				},
			],
		},
		// {
		// 	label: "Rooms",
		// 	link: WebRoutes.CONTACT_US,
		// },
		{
			label: "Media",
			link: "#",
			links: [
				{
					label: "Press Centre",
					link: WebRoutes.PRESS_CENTRE,
				},
				{
					label: "In The News",
					link: WebRoutes.IN_THE_NEWS,
				},
			],
		},
		{
			label: "Partners",
			link: "#",
			links: [
				{
					label: "Partners",
					link: WebRoutes.PARTNERS,
				},
			],
		},
		{
			label: "Contact Us",
			link: WebRoutes.CONTACT_US,
		},
	];

	const items = _MENU.map((link) => {
		const menuItems = link.links?.map((item) => (
			<Link to={item.link}>
				<Menu.Item key={item.link}>{item.label}</Menu.Item>
			</Link>
		));

		if (menuItems) {
			return (
				<Menu key={link.label} trigger="hover" exitTransitionDuration={0}>
					<Link to={link.link}>
						<Menu.Target>
							<div className="navbar-item">{link.label}</div>
						</Menu.Target>
					</Link>
					<Menu.Dropdown>{menuItems}</Menu.Dropdown>
				</Menu>
			);
		}

		return (
			<Link to={link.link}>
				<div className="navbar-item">{link.label}</div>
			</Link>
		);
	});

	return (
		<Header id="navbar" sx={{ backgroundColor: "#fffaf4" }}>
			{items}
		</Header>
	);
}

export default Navbar;
