import React from "react";
import { Grid } from "@mantine/core";

/// <summary>
/// Author: JavaChips
/// </summary>
const Introduction = () => {
	return (
		<div id="introduction">
			<Grid gutter={30} align="center">
				<Grid.Col xs={12} lg={6} className="logo">
					<img src={require("../assets/images/gtlf-logo.png")} alt="GTLF Logo" />
				</Grid.Col>
				<Grid.Col xs={12} lg={6} className="content">
					<h3>About Us</h3>
					<p>
						George Town Literary Festival (GTLF) is Malaysia’s largest international literary festival. Supported by the State Government of Penang, it is held annually in the UNESCO World Heritage site of George Town, Penang. GTLF is one of the best-recognised platforms for free speech in Malaysia with a specific focus on world literature. The festival believes in the power of expression and is committed to being one of the most urgent, vital and provocative literary festivals in
						the region. Each year, selected local and international writers, poets and performers engage in topics and themes that are crucial to the present state of the world.
					</p>
					<b>Objectives</b>
					<ul>
						<li>Encourage a meaningful exchange of ideas between local and international writers and thinkers</li>
						<li>Create a platform for vibrant discussion encompassing a plurality of voices and perspectives</li>
						<li>Facilitate intellectual discourse on pertinent local, regional and global topics</li>
						<li>Celebrate Penang and Malaysia as a cosmopolitan centre for the flourishing of literature and ideas</li>
						<li>Expose Malaysians to world literature and promote reading, writing, translation, and publishing</li>
						<li>Aid the local publishing industry by creating a strong reading culture</li>
						<li>Catalyse multi-disciplinary skill and knowledge growth through lectures, discussions, and workshops</li>
					</ul>
				</Grid.Col>
			</Grid>
		</div>
	);
};

export default Introduction;
