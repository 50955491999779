import React from "react";
import { useCountdown } from "../hooks/useCountdown";
import moment from "moment";
import useAppStore from "../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
const Countdown = () => {
	const gtlfState = useAppStore((state) => state.gtlf);
	const [months, days, hours, minutes, seconds] = useCountdown(moment(gtlfState?.end_date, "YYYY-MM-DD H:i:s"));

	return (
		<div id="countdown">
			<div className="countdown-item">
				<div className="value">{months > 0 ? months : 0}</div>
				<div className="label">Months</div>
			</div>
			<div className="countdown-item">
				<div className="value">{days > 0 ? days : 0}</div>
				<div className="label">Days</div>
			</div>
			<div className="countdown-item">
				<div className="value">{hours > 0 ? hours : 0}</div>
				<div className="label">Hours</div>
			</div>
			<div className="countdown-item">
				<div className="value">{minutes > 0 ? minutes : 0}</div>
				<div className="label">Minutes</div>
			</div>
			<div className="countdown-item">
				<div className="value">{seconds > 0 ? seconds : 0}</div>
				<div className="label">Seconds</div>
			</div>
		</div>
	);
};

export default Countdown;
