import { useEffect, useState } from "react";
import { Grid, Modal } from "@mantine/core";
import moment from "moment";

import useAppStore from "../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
function Gtlf() {
	const gtlfState = useAppStore((state) => state.gtlf);
	const [opened, setOpened] = useState(false);
	const [modalData, setModalData] = useState({});

	console.log(gtlfState);

	useEffect(() => {
		document.title = `#GTLF${gtlfState?.year || moment().format("YYYY")} | GTLF ${gtlfState?.year || moment().format("YYYY")}`;
	}, []);

	useEffect(() => {
		setOpened(Object.values(modalData).length > 0);
	}, [modalData]);

	useEffect(() => {
		if (!opened) {
			setTimeout(() => {
				setModalData({});
			}, 500);
		}
	}, [opened]);

	return (
		<div id="gtlf">
			<div className="title">
				<h3>
					GTLF {gtlfState?.year}: {gtlfState?.title}
				</h3>
			</div>

			<Grid justify="center" gutter={40}>
				<Grid.Col xs={12} lg={4}>
					<div className="gtlf-img">
						<img src={gtlfState?.image} alt={`GTLF ${gtlfState?.year} Theme`} />
					</div>
				</Grid.Col>
				<Grid.Col xs={12} lg={8}>
					<div className="sub-text">{gtlfState?.theme}</div>
					<div className="text">{gtlfState?.desc}</div>
				</Grid.Col>
			</Grid>

			{gtlfState?.curatorialTeam?.length > 0 && (
				<>
					<div className="curatorial-title">
						<h3>Curatorial Team</h3>
					</div>
					<Grid justify="center" gutter={40}>
						<Grid.Col xs={12} lg={3}>
							<div className="curator-img" onClick={() => setModalData(gtlfState?.curatorialTeam.find((x) => x.position === "Festival Director"))}>
								<img src={gtlfState?.curatorialTeam.find((x) => x.position === "Festival Director").image} alt="Festival Director" />
								<div className="curator-hover">
									<span>See More</span>
								</div>
							</div>
							<div className="curator-name">{gtlfState?.curatorialTeam.find((x) => x.position === "Festival Director").name}</div>
							<div className="curator-role">{gtlfState?.curatorialTeam.find((x) => x.position === "Festival Director").position}</div>
						</Grid.Col>
					</Grid>
					<Grid justify="center" gutter={40} style={{ marginTop: "40px" }}>
						{gtlfState?.curatorialTeam
							.filter((x) => x.position !== "Festival Director")
							.map((item) => (
								<Grid.Col xs={12} lg={3}>
									<div className="curator-img" onClick={() => setModalData(item)}>
										<img src={item.image} alt="Festival Curator" />
										<div className="curator-hover">
											<span>See More</span>
										</div>
									</div>
									<div className="curator-name">{item.name}</div>
									<div className="curator-role">{item.position}</div>
								</Grid.Col>
							))}
					</Grid>
				</>
			)}

			<Modal overflow="inside" centered opened={opened} onClose={() => setOpened(false)} size={window.innerWidth >= 1600 ? "55%" : window.innerWidth >= 1200 ? "75%" : window.innerWidth >= 768 ? "85%" : "100%"}>
				<div className="modal-body">
					<img className="modal-img" src={modalData?.image} alt="Festival Director" />
					<h3>{modalData?.name}</h3>
					<h4>{modalData?.title}</h4>
					<p dangerouslySetInnerHTML={{ __html: modalData?.desc }}></p>
				</div>
			</Modal>
		</div>
	);
}

export default Gtlf;
