import React from "react";

import Introduction from "../../components/Introduction";

/// <summary>
/// Author: JavaChips
/// </summary>
const AboutUs = () => {
	return <Introduction />;
};

export default AboutUs;
