import { create } from 'zustand';

const useAppStore = create((set) => ({
    gtlf: null,
    updateGtlf: (newState) =>
        set((state) => ({
            gtlf: newState,
        })),
}));

export default useAppStore;
