import React from "react";
import moment from "moment";

/// <summary>
/// Author: JavaChips
/// </summary>
const SocialMedia = () => {
	return (
		<div id="socialMedia">
			<a href="https://www.instagram.com/georgetownlitfest/" target="_blank" rel="noopener noreferrer">
				<img src={require("../assets/images/icons/instagram.png")} alt="Instagram Icon" />
			</a>
			<a href="https://www.facebook.com/georgetownlitfest/" target="_blank" rel="noopener noreferrer">
				<img src={require("../assets/images/icons/facebook.png")} alt="Facebook Icon" />
			</a>
			<a href="https://twitter.com/georgetownlit/" target="_blank" rel="noopener noreferrer">
				<img src={require("../assets/images/icons/twitter.png")} alt="Twitter Icon" />
			</a>
		</div>
	);
};

export default SocialMedia;
