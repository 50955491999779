import { Badge, Button, Grid, Modal, ScrollArea, Tabs, Text } from "@mantine/core";
import { useEffect, useState } from "react";

import moment from "moment";
import useAppStore from "../../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
function FestivalProgramme() {
	const gtlfState = useAppStore((state) => state.gtlf);
	const [opened, setOpened] = useState(false);
	const [modalData, setDescriptionPopup] = useState(null);

	useEffect(() => {
		document.title = `#GTLF${gtlfState?.year || moment().format("YYYY")} | Festival Programme ${gtlfState?.year || moment().format("YYYY")}`;
	}, []);

	useEffect(() => {
		setOpened(modalData !== null);
	}, [modalData]);

	useEffect(() => {
		if (!opened) {
			setTimeout(() => {
				setDescriptionPopup(null);
			}, 500);
		}
	}, [opened]);

	return (
		<div id="festivalProgramme">
			<div className="gtlf-img">
				<img src={gtlfState?.image} alt={`GTLF ${gtlfState?.year} Theme`} />
			</div>
			<div className="title">
				<h3>Festival Programme</h3>
			</div>
			<Tabs color="#006bb3" variant="pills" defaultValue="1">
				<Tabs.List position="center">{gtlfState?.festivalProgrammes && Object.keys(gtlfState?.festivalProgrammes).map((day, index) => <Tabs.Tab value={(index + 1).toString()}>Day {index + 1}</Tabs.Tab>)}</Tabs.List>
				{gtlfState?.festivalProgrammes &&
					Object.keys(gtlfState?.festivalProgrammes).map((day, index) => (
						<Tabs.Panel value={(index + 1).toString()}>
							<h4>{day}</h4>

							<ScrollArea horizontal style={{ width: "100%" }}>
								<div className="programme-list">
									{gtlfState?.festivalProgrammes?.[day]?.map((key) => (
										<div className="programme-item">
											<div className="container">
												<div className="programme-header">{key?.locationName}</div>
												{key.programmes.map((item) => (
													<div className="content">
														<Text
															onClick={() => {
																if (item?.desc !== "<p></p>") {
																	setDescriptionPopup(item?.desc);
																}
															}}
															weight="bold"
															underline
															size="md"
															style={{ whiteSpace: "pre-line", cursor: "pointer" }}
															dangerouslySetInnerHTML={{ __html: item.title }}
														></Text>
														{item.speakers && (
															<Text weight="bold" color="#9f9f9e">
																Speakers:{" "}
																<Text span color="dimmed" weight="normal">
																	{item.speakers}
																</Text>
															</Text>
														)}
														{item.moderator && (
															<Text weight="bold" color="#9f9f9e">
																Moderator:{" "}
																<Text span color="dimmed" weight="normal">
																	{item.moderator}
																</Text>
															</Text>
														)}
														{item.host && (
															<Text weight="bold" color="#9f9f9e">
																Host:{" "}
																<Text span color="dimmed" weight="normal">
																	{item.host}
																</Text>
															</Text>
														)}
														{item?.director && (
															<Text weight="bold" color="#9f9f9e">
																Director:{" "}
																<Text span color="dimmed" weight="normal">
																	{item.director}
																</Text>
															</Text>
														)}
														{item?.runtime && (
															<Text weight="bold" color="#9f9f9e">
																Runtime:{" "}
																<Text span color="dimmed" weight="normal">
																	{item.runtime}
																</Text>
															</Text>
														)}
														<Badge fullWidth variant="filled" size="lg" my={10}>
															<Text>{item.timeslot}</Text>
														</Badge>
														{item?.link && (
															<Button fullWidth component="a" href={item?.link} target="_blank" color="orange">
																Purchase Tickets
															</Button>
														)}
													</div>
												))}
											</div>
										</div>
									))}
								</div>
							</ScrollArea>
						</Tabs.Panel>
					))}
			</Tabs>
			<Modal overflow="inside" centered opened={opened} onClose={() => setOpened(false)} size={window.innerWidth >= 1600 ? "55%" : window.innerWidth >= 1200 ? "75%" : window.innerWidth >= 768 ? "85%" : "100%"}>
				<div className="modal-body" style={{ padding: 20 }}>
					<p dangerouslySetInnerHTML={{ __html: modalData }}></p>
				</div>
			</Modal>
		</div>
	);
}

export default FestivalProgramme;
