import { useEffect, useState } from "react";
import { Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons";
import supabase from "../../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function PressCentre() {
	const [presses, setPresses] = useState([]);

	useEffect(() => {
		document.title = `#GTLF2023 | Press Centre`;

		(async () => {
			const { data, error } = await supabase.from("press-centre").select("*").eq("is_deleted", false).order("index", { ascending: false });

			if (error !== null) {
				throw error.message;
			}

			setPresses(data);
		})();
	}, []);

	return (
		<div id="pressCentre">
			<div className="title">
				<h2>Press and Social Media</h2>
				<p>For media queries please email info@georgetownlitfest.com</p>
			</div>
			{presses.map((item, index) => (
				<div className={`press-item ${index !== presses.length - 1 && "border"}`}>
					<h3>{item.title}</h3>
					<p>{item.date}</p>
					<Button component="a" href={item.file} target="_blank" rightIcon={<IconDownload size={16} />} variant="outline" color="dark">
						View
					</Button>
				</div>
			))}
		</div>
	);
}

export default PressCentre;
