import { Grid, Modal } from "@mantine/core";
import moment from "moment";
import { useEffect, useState } from "react";

import useAppStore from "../../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
function Gtlf() {
	const gtlfState = useAppStore((state) => state.gtlf);
	const [opened, setOpened] = useState(false);
	const [modalData, setModalData] = useState({});

	useEffect(() => {
		document.title = `#GTLF${gtlfState?.year || moment().format("YYYY")} | Line-Ups ${gtlfState?.year || moment().format("YYYY")}`;
	}, []);

	useEffect(() => {
		setOpened(Object.values(modalData).length > 0);
	}, [modalData]);

	useEffect(() => {
		if (!opened) {
			setTimeout(() => {
				setModalData({});
			}, 500);
		}
	}, [opened]);

	const sortAscending = (a, b) => {
		var textA = a.name.toUpperCase();
		var textB = b.name.toUpperCase();

		return textA < textB ? -1 : textA > textB ? 1 : 0;
	};

	return (
		<div id="gtlf">
			<div className="curatorial-title">
				<h3>Line-Ups</h3>
			</div>
			<Grid justify="center" gutter={40} style={{ marginTop: "40px" }}>
				{gtlfState?.lineUps?.sort(sortAscending)?.map((item) => (
					<Grid.Col xs={12} lg={3}>
						<div className="curator-img" onClick={() => setModalData(item)}>
							<img src={item.image} alt="" />
							<div className="curator-hover">
								<span>See More</span>
							</div>
						</div>
						<div className="curator-name">{item.name}</div>
					</Grid.Col>
				))}
			</Grid>

			<Modal overflow="inside" centered opened={opened} onClose={() => setOpened(false)} size={window.innerWidth >= 1600 ? "55%" : window.innerWidth >= 1200 ? "75%" : window.innerWidth >= 768 ? "85%" : "100%"}>
				<div className="modal-body">
					<img className="modal-img" src={modalData?.image} alt="" />
					<h3>{modalData?.name}</h3>
					<p dangerouslySetInnerHTML={{ __html: modalData?.desc }}></p>
				</div>
			</Modal>
		</div>
	);
}

export default Gtlf;
