export default {
	HOME: "/",

	ABOUT_US: "the-festival/about-us",
	PRODUCTION_TEAM: "the-festival/production-team",
	MUARA: "the-festival/muara",
	PAST_FESTIVALS: "the-festival/past-festivals",
	LINE_UPS: "the-festival/line-ups",
	FESTIVAL_PROGRAMME: "the-festival/festival-programme",
	HAPPENING_SIMULTANEOUSLY: "the-festival/happening-sumultaneously",
	PARTNERS: "partners",

	GTLF: "gtlf/details",

	CONTACT_US: "contact-us",

	ROOM: "room",

	PRESS_CENTRE: "media/press-centre",
	IN_THE_NEWS: "media/in-the-news",

	PARTNER_WITH_US: "partner-with-us",

	OPEN_CALL: "/the-festival/open-call",

	MUARA_WRITING_PRIZE: "the-festival/muara-writing-prize",
};
