import { useEffect, useState } from "react";
import { Box, Center, Grid, Modal, Text } from "@mantine/core";
import supabase from "../../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function MuaraWritingPrize() {
	const [openCall, setOpenCall] = useState(null);
	const [magnifyImage, setMagnifyImage] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		document.title = `#GTLF2023 | Muara Writing Prize`;

		(async () => {
			const { data, error } = await supabase
				.from("muara-writing-competition")
				.select(
					`
            content,
            muara-writing-competition-sections(
              image,
              content
            )
          `
				)
				.limit(1)
				.single();

			if (error !== null) {
				throw error.message;
			}

			setOpenCall(data);
		})();
	}, []);

	useEffect(() => {
		setModalVisible(magnifyImage !== null);
	}, [magnifyImage]);

	return (
		<div id="productionTeam">
			<div className="title" style={{ marginBottom: 50 }}>
				<h2>Muara Writing Prize</h2>
			</div>
			{openCall === null && (
				<Center style={{ height: 400 }}>
					<Text color="black">Muara Writing Prize is empty for now. Please check back at a later time!</Text>
				</Center>
			)}
			{openCall !== null &&
				openCall["muara-writing-competition-sections"].map((item) => (
					<div className="team-member">
						<Grid gutter={40} align="center">
							<Grid.Col md={3}>
								<img src={item.image} />
							</Grid.Col>
							<Grid.Col md={9}>
								<div dangerouslySetInnerHTML={{ __html: item?.content }} />
							</Grid.Col>
						</Grid>
					</div>
				))}
		</div>
	);
}

export default MuaraWritingPrize;
