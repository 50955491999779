import { useEffect } from "react";
import { Grid } from "@mantine/core";

/// <summary>
/// Author: JavaChips
/// </summary>
function ProductionTeam() {
	useEffect(() => {
		document.title = `#GTLF2023 | Production Team`;
	}, []);

	return (
		<div id="productionTeam">
			<div className="title">
				<h3>Meet The Team</h3>
			</div>
			<div className="team-member">
				<Grid gutter={40} align="center">
					<Grid.Col md={3}>
						<img className="no-circle" src={require("../../assets/images/pceb-logo.png")} alt="Festival Producer" />
					</Grid.Col>
					<Grid.Col md={9}>
						<div className="name">
							<b>Penang Convention & Exhibition Bureau, Festival Producer</b>
						</div>
						<p>
							Penang Convention & Exhibition Bureau (PCEB) is a state bureau established in 2016 to develop the Business Events and meetings, incentives, conventions and exhibitions (MICE) industry in Penang. PCEB took over the reins of GTLF in 2016 as Festival Producer. Under the care of PCEB, GTLF has undergone rebranding and even gained international recognition at the London Book Fair in 2017 and 2018. The bureau has grown and nurtured the festival to be on par with other
							globally recognized international festivals.
						</p>
					</Grid.Col>
				</Grid>
			</div>

			<div className="team-member">
				<Grid gutter={40} align="center">
					<Grid.Col md={3}>
						<img src={require("../../assets/images/people/swarna-rajagopal.JPG")} alt="Festival Manager" />
					</Grid.Col>
					<Grid.Col md={9}>
						<div className="name">
							<b>Swarna Rajagopal, Festival Manager</b>
						</div>
						<p>Swarna Rajagopal had been active in public policy research when she ventured into the arts. She reads and occasionally writes but they are mostly kept secret. Swarna has been involved in the past 4 editions of the George Town Literary Festival. </p>
					</Grid.Col>
				</Grid>
			</div>

			<div className="team-member">
				<Grid gutter={40} align="center">
					<Grid.Col md={3}>
						<img src={require("../../assets/images/people/desmond.jpg")} alt="Festival Coordinator" />
					</Grid.Col>
					<Grid.Col md={9}>
						<div className="name">
							<b>Desmond Ngooi, Festival Coordinator</b>
						</div>
						<p>
							Desmond has always been passionate about all art forms. His passion first started when he joined the Penang Symphony Orchestra as a musician. From there he journeyed into performing arts where he joined the Performing Arts Center of Penang wearing many hats such as Box Office Supervisor, Front of House Manager, Stage Manager, Production Manager and even debuted as an actor there. Now Desmond has joined George Town Literary Festival, hoping to learn and grow more to
							fuel his passion for the arts.
						</p>
					</Grid.Col>
				</Grid>
			</div>
		</div>
	);
}

export default ProductionTeam;
