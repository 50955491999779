import { useEffect, useState } from "react";
import { Grid, Modal } from "@mantine/core";
import supabase from "../../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function Muara() {
	const [muaras, setMuaras] = useState([]);

	useEffect(() => {
		document.title = `#GTLF2023 | Muara`;

		(async () => {
			const { data, error } = await supabase.from("muara").select("*").eq("is_deleted", false).order("created_at", { ascending: false });

			if (error !== null) {
				throw error.message;
			}

			setMuaras(data);
		})();
	}, []);

	return (
		<div id="muara">
			<div className="muara-container">
				<img className="muara-logo" src={require("../../assets/images/muara-logo.png")} alt="Muara Masthead" />
				<div style={{ textAlign: "left" }}>
					<p>Muara, the Malay word for estuary, is a passage of fluidity and connection, where the river meets the open sea. Muara evokes a place where we find ourselves between the memory of the land and the lure of the unknown deep. In these pages, writers engage in ideas and imaginings, interpreting the shifting shoreline of our realities. The ink we leave upon this topography are traces of ourselves, inscribing this moment in the ebb and flow of existence.</p>
					<p>Muara started as a collaboration between George Town Literary Festival (GTLF) and Svara journal to celebrate the 10th Anniversary of GTLF, and Penang as a vibrant port and cosmopolitan city in the Southeast Asian region.</p>
					<p>Muara is now an annual GTLF publication featuring essays, short stories, poetry, book reviews, and translations, Muara gathers established and emerging writers from Malaysia, the region, and the world.</p>
				</div>
			</div>
			<Grid gutter={30} style={{ marginTop: 20 }}>
				{muaras.map((item) => (
					<Grid.Col xs={12} lg={3}>
						<a href={item.link} target="_blank">
							<div className="muara-item">
								<img className="muara-img" src={item.image} alt="Muara Smaller Banner" />
								<h4>{item.title}</h4>
							</div>
						</a>
					</Grid.Col>
				))}
			</Grid>
		</div>
	);
}

export default Muara;
