import { Grid } from "@mantine/core";
import moment from "moment";
import { useEffect, useState } from "react";
import supabase from "../../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function Partners() {
	const [partners, setPartners] = useState([]);

	useEffect(() => {
		document.title = `#GTLF2023 | Partners ${moment().format("YYYY")}`;

		(async () => {
			const { data, error } = await supabase.from("partner-category").select("*, images:partners(image, id)").eq("is_deleted", false).order("index", { ascending: true });

			if (error !== null) {
				throw error.message;
			}

			setPartners(data);
		})();
	}, []);

	return (
		<div id="gtlf">
			<div className="title">
				<h2>Partners</h2>
			</div>

			{partners?.map((partner) => (
				<>
					<div className="curatorial-title" style={{ marginTop: 50, marginBottom: 20 }}>
						<h4>{partner?.title?.toUpperCase()}</h4>
					</div>
					<Grid justify="center" gutter={40}>
						{partner?.images
							?.sort((p1, p2) => (p1.id < p2.id ? -1 : 0))
							?.map((image) => (
								<Grid.Col xs={12} lg={3} style={{ textAlign: "center" }}>
									<img style={{ width: "60%", aspectRatio: 1, objectFit: "contain", margin: "auto" }} src={image?.image} alt="" />
								</Grid.Col>
							))}
					</Grid>
				</>
			))}
		</div>
	);
}

export default Partners;
