import React from "react";
import ReactDOM from "react-dom/client";

import "./scss/main.scss";

import App from "./App";
import Home from "./pages/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import WebRoutes from "./utils/WebRoutes";

import reportWebVitals from "./reportWebVitals";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/about-us";
import ProductionTeam from "./pages/production-team";
import Gtlf from "./pages/Gtlf";
import Muara from "./pages/muara";
import PastFestivals from "./pages/past-festivals";
import PartnerWithUs from "./pages/partner-with-us";
import InTheNews from "./pages/in-the-news";
import PressCentre from "./pages/press-centre";
import LineUps from "./pages/line-up";
import Partners from "./pages/partners";
import FestivalProgramme from "./pages/festival-programme";
import HappeningSimultaneously from "./pages/happening-simultaneously";
import OpenCall from "./pages/open-call";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import MuaraWritingPrize from "./pages/muara-writing-prize";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<MantineProvider>
			<NotificationsProvider>
				<BrowserRouter>
					<Routes>
						<Route path={WebRoutes.HOME} element={<App />}>
							<Route index element={<Home />} />
							<Route path={WebRoutes.GTLF} element={<Gtlf />} />
							<Route path={WebRoutes.CONTACT_US} element={<ContactUs />} />
							<Route path={WebRoutes.ABOUT_US} element={<AboutUs />} />
							<Route path={WebRoutes.PRODUCTION_TEAM} element={<ProductionTeam />} />
							<Route path={WebRoutes.MUARA} element={<Muara />} />
							<Route path={WebRoutes.PAST_FESTIVALS} element={<PastFestivals />} />
							<Route path={WebRoutes.PARTNER_WITH_US} element={<PartnerWithUs />} />
							<Route path={WebRoutes.LINE_UPS} element={<LineUps />} />
							<Route path={WebRoutes.PARTNERS} element={<Partners />} />
							<Route path={WebRoutes.IN_THE_NEWS} element={<InTheNews />} />
							<Route path={WebRoutes.PRESS_CENTRE} element={<PressCentre />} />
							<Route path={WebRoutes.FESTIVAL_PROGRAMME} element={<FestivalProgramme />} />
							<Route path={WebRoutes.HAPPENING_SIMULTANEOUSLY} element={<HappeningSimultaneously />} />
							<Route path={WebRoutes.OPEN_CALL} element={<OpenCall />} />
							<Route path={WebRoutes.MUARA_WRITING_PRIZE} element={<MuaraWritingPrize />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</NotificationsProvider>
		</MantineProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
