import { useEffect, useState } from "react";
import { Grid, Modal } from "@mantine/core";
import SvgTick from "../../components/SvgTick";
import SvgCross from "../../components/SvgCross";

/// <summary>
/// Author: JavaChips
/// </summary>
function PartnerWithUs() {
	useEffect(() => {
		document.title = `#GTLF2023 | Partner With Us`;
	}, []);

	return (
		<div id="partnerWithUs">
			<div className="title">
				<h2>Partner With Us</h2>
			</div>
			<div className="title">
				<h4>GEORGE TOWN LITERARY FESTIVAL SPONSORSHIP PACKAGES</h4>
				<p>The George Town Literary Festival Curatorial and Production Team welcomes and appreciates your kind support.</p>
			</div>

			<Grid gutter={30}>
				<Grid.Col xs={12} md={6} lg={3}>
					<div className="tier">
						<h3 className="tier-title">
							Platinum Partner
							<br />
							(RM50,000 - RM100,000)
						</h3>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF website (with hyperlink)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF e-newsletters</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF backdrops (select)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Name mentioned in the acknowledgement page of the GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									Full colour advertisement in GTLF programme booklet
									<br />
									<b>(2 Pages)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Opening Ceremony
									<br />
									<b>(5 Invites)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Welcome Dinner
									<br />
									<b>(2 Invites)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Press Conference with Chief Minister of Penang to announce sponsorship</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>E-newsletter announcement on the sponsorship</Grid.Col>
							</Grid>
						</div>
					</div>
				</Grid.Col>

				<Grid.Col xs={12} md={6} lg={3}>
					<div className="tier">
						<h3 className="tier-title">
							Gold Partner
							<br />
							(RM20,000 – RM49,000)
						</h3>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF website (with hyperlink)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF e-newsletters</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF backdrops (select)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Name mentioned in the acknowledgement page of the GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									Full colour advertisement in GTLF programme booklet
									<br />
									<b>(1 Pages)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Opening Ceremony
									<br />
									<b>(3 Invites)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Welcome Dinner
									<br />
									<b>(1 Invites)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Press Conference with Chief Minister of Penang to announce sponsorship</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>E-newsletter announcement on the sponsorship</Grid.Col>
							</Grid>
						</div>
					</div>
				</Grid.Col>

				<Grid.Col xs={12} md={6} lg={3}>
					<div className="tier">
						<h3 className="tier-title">
							Partner
							<br />
							(RM5,000 – RM9,000)
						</h3>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF website (with hyperlink)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF e-newsletters</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF backdrops (select)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Name mentioned in the acknowledgement page of the GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									Full colour advertisement in GTLF programme booklet
									<br />
									<b>(1 Pages)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Opening Ceremony
									<br />
									<b>(2 Invites)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Welcome Dinner
									<br />
									<br />
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>Press Conference with Chief Minister of Penang to announce sponsorship</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>E-newsletter announcement on the sponsorship</Grid.Col>
							</Grid>
						</div>
					</div>
				</Grid.Col>

				<Grid.Col xs={12} md={6} lg={3}>
					<div className="tier">
						<h3 className="tier-title">
							Friends of GTLF
							<br />
							(RM1,000 – RM4,000)
						</h3>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF website (with hyperlink)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF e-newsletters</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF backdrops (select)</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>Logo published on GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>Name mentioned in the acknowledgement page of the GTLF programme booklet</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>
									Full colour advertisement in GTLF programme booklet
									<br />
									<b>(2 Pages)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgTick />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Opening Ceremony
									<br />
									<b>(1 Invites)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>
									VIP invite to the Welcome Dinner
									<br />
									<b>(2 Invites)</b>
								</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item border">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>Press Conference with Chief Minister of Penang to announce sponsorship</Grid.Col>
							</Grid>
						</div>
						<div className="tier-item">
							<Grid>
								<Grid.Col span={2}>
									<SvgCross />
								</Grid.Col>
								<Grid.Col span={10}>E-newsletter announcement on the sponsorship</Grid.Col>
							</Grid>
						</div>
					</div>
				</Grid.Col>
			</Grid>
		</div>
	);
}

export default PartnerWithUs;
