import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Button } from "@mantine/core";

/// <summary>
/// Author: JavaChips
/// </summary>
function Newsletter() {
	return (
		<div id="newsletter">
			<img src={require("../assets/images/banners/newsletter-banner.jpg")} alt=" Newsletter Banner" />
			<div className="content">
				<div className="title">
					<h3>Subscribe To Our Newsletter</h3>
				</div>
				<h1>Get latest news about GTLF!</h1>
				<Button component="a" href="http://list8.ebuzzzz.com/index.php?action=form&type=html&id=1080" target="_blank" variant="outline">
					SUBSCRIBE NOW
				</Button>
			</div>
		</div>
	);
}

export default Newsletter;
