import { useEffect, useState } from "react";
import { Tabs, Button } from "@mantine/core";
import supabase from "../../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function InTheNews() {
	const [inTheNews, setInTheNews] = useState([]);

	useEffect(() => {
		document.title = `#GTLF2023 | In The News`;

		(async () => {
			const { data, error } = await supabase.from("in-the-news").select("*").eq("is_deleted", false).order("year", { ascending: false });

			if (error !== null) {
				throw error.message;
			}

			let groupedObj = data.reduce(function (r, a) {
				r[a.year] = r[a.year] || [];
				r[a.year].push(a);
				return r;
			}, Object.create(null));

			let groupedData = Object.keys(groupedObj)
				.reverse()
				.map((key) => ({
					year: key,
					items: groupedObj[key]
						.map((innerItem) => ({
							publisher: innerItem.title,
							title: innerItem.desc,
							link: innerItem.link,
						}))
						.reverse(),
				}));

			setInTheNews(groupedData);
		})();
	}, []);

	return (
		<div id="inTheNews">
			<div className="title">
				<h2>In The News</h2>
			</div>
			<Tabs orientation="vertical" defaultValue="2022">
				<Tabs.List>
					{inTheNews.map((year) => (
						<Tabs.Tab value={year?.year}>{year?.year}</Tabs.Tab>
					))}
				</Tabs.List>

				{inTheNews.map((year) => (
					<Tabs.Panel value={year?.year} pl="xs">
						{year?.items.map((item) => (
							<div className="news-item">
								<h5>{item.publisher}</h5>
								<p>{item.title}</p>
								<Button component="a" href={item.link} target="_blank" variant="outline" color="gray" compact>
									View More
								</Button>
							</div>
						))}
					</Tabs.Panel>
				))}
			</Tabs>
		</div>
	);
}

export default InTheNews;
