import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import supabase from "../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function Banner() {
	const [banners, setBanners] = useState([]);

	useEffect(() => {
		(async () => {
			const { data, error } = await supabase.from("banners").select("*").match({ is_deleted: false, active: true }).order("index", { ascending: false });

			if (error !== null) {
				throw error.message;
			}

			setBanners(data);
		})();
	}, []);

	return (
		<Carousel dynamicHeight={true} swipeable={true} autoPlay={true} interval={5000} infiniteLoop={true} showThumbs={false}>
			{banners.map((item) => (
				<div key={item.id}>
					<img src={item.image} alt="" />
				</div>
			))}
		</Carousel>
	);
}

export default Banner;
