import { useEffect, useState } from "react";
import { Grid, Modal } from "@mantine/core";
import moment from "moment";

import useAppStore from "../../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
function HappeningSimultaneously() {
	const gtlfState = useAppStore((state) => state.gtlf);
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		document.title = `#GTLF${gtlfState?.year || moment().format("YYYY")} | Happening Simultaneously ${gtlfState?.year || moment().format("YYYY")}`;
	}, []);

	return (
		<div id="pastFestivals">
			<div className="title">
				<h3>Happening Simultaneously</h3>
			</div>
			<Grid gutter={30}>
				{gtlfState?.happeningSimultaneously?.map((item) => (
					<Grid.Col xs={12} md={4} lg={4}>
						<div
							className="festival-item"
							onClick={() => {
								if (item?.desc !== "<p></p>") {
									setOpened(item);
								}
							}}
						>
							{item?.link !== null && (
								<a href={item?.link} target="_blank">
									<img src={item.image} alt="Past Festival" />
								</a>
							)}
							{item?.link === null && (
								<a href="javascript:;">
									<img src={item.image} alt="Past Festival" />
								</a>
							)}
						</div>
					</Grid.Col>
				))}
			</Grid>
			<Modal overflow="inside" centered opened={opened} onClose={() => setOpened(false)} size={window.innerWidth >= 1600 ? "55%" : window.innerWidth >= 1200 ? "75%" : window.innerWidth >= 768 ? "85%" : "100%"}>
				<div className="modal-body" style={{ padding: 20 }}>
					<p dangerouslySetInnerHTML={{ __html: opened?.desc }}></p>
				</div>
			</Modal>
		</div>
	);
}

export default HappeningSimultaneously;
