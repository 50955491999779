import { Grid } from "@mantine/core";
import React from "react";

import Banner from "../components/Banner";
import Countdown from "../components/Countdown";
import Newsletter from "../components/Newsletter";
import useAppStore from "../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
const Home = () => {
	const gtlfState = useAppStore((state) => state.gtlf);

	return (
		<main id="home">
			<Banner />
			<Countdown />
			{gtlfState !== null && (
				<div id="introduction">
					<Grid align="center">
						<Grid.Col xs={12} md={6}>
							<div className="logo special">
								<img src={gtlfState?.image} alt="GTLF Theme 2022" />
							</div>
						</Grid.Col>
						<Grid.Col xs={12} md={6}>
							<div className="content">
								<h3>GTLF {gtlfState?.year}</h3>
								<div style={{ textAlign: "center", marginBottom: 20 }}>
									<b>{gtlfState?.title}</b>
								</div>
								<i style={{ color: "#9f9f9e", textAlign: "justify" }}>{gtlfState?.theme}</i>
								<p style={{ color: "#716d6d" }}>{gtlfState?.desc}</p>
							</div>
						</Grid.Col>
					</Grid>
				</div>
			)}

			<div id="introduction" style={{ backgroundColor: "white" }}>
				<Grid align="center">
					<Grid.Col orderXs={2} order={1} xs={12} md={6}>
						<div className="content">
							<h3>Winner</h3>
							<div style={{ textAlign: "center" }}>
								<b>The London Book Fair International Excellence Awards 2018 Literary Festival</b>
							</div>
							<p>“In a strong field, George Town Literary Festival stands out as a vibrant, diverse and brave festival that engages with a wide community of voices, speaking to the world from a complex region.” – Judges of The London Book Fair International Excellence Awards 2018</p>
						</div>
					</Grid.Col>
					<Grid.Col orderXs={1} order={2} xs={12} md={6}>
						<div className="logo special">
							<img src={require("../assets/images/award-winner.png")} alt="Award Winner Logo" />
						</div>
					</Grid.Col>
				</Grid>
			</div>
			<Newsletter />
		</main>
	);
};

export default Home;
