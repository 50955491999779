import { useEffect } from "react";
import { Link } from "react-router-dom";
import WebRoutes from "../utils/WebRoutes";

import { Drawer, Menu } from "@mantine/core";
import moment from "moment";

import useAppStore from "../hooks/zustand/useAppStore";

/// <summary>
/// Author: JavaChips
/// </summary>
function MobileNavbar({ opened, setOpened }) {
	const gtlfState = useAppStore((state) => state.gtlf);

	const _MENU = [
		{
			label: "The Festival",
			link: "#",
			links: [
				{
					label: "About Us",
					link: WebRoutes.ABOUT_US,
				},
				{
					label: "Muara",
					link: WebRoutes.MUARA,
				},
				{
					label: "Past Festivals",
					link: WebRoutes.PAST_FESTIVALS,
				},
			],
		},
		{
			label: "GTLF " + (gtlfState?.year || moment().format("YYYY")),
			link: "#",
			links: [
				{
					label: gtlfState?.alias || `GTLF ${moment().format("YYYY")}`,
					link: WebRoutes.GTLF,
				},
				{
					label: "Line-Ups",
					link: WebRoutes.LINE_UPS,
				},
				{
					label: "Festival Programme",
					link: WebRoutes.FESTIVAL_PROGRAMME,
				},
				{
					label: "Happening Simultaneously",
					link: WebRoutes.HAPPENING_SIMULTANEOUSLY,
				},
				{
					label: "Open Call",
					link: WebRoutes.OPEN_CALL,
				},
			],
		},
		{
			label: "Media",
			link: "#",
			links: [
				{
					label: "Press Centre",
					link: WebRoutes.PRESS_CENTRE,
				},
				{
					label: "In The News",
					link: WebRoutes.IN_THE_NEWS,
				},
			],
		},
		{
			label: "Partners",
			link: "#",
			links: [
				{
					label: "Partners",
					link: WebRoutes.PARTNERS,
				},
			],
		},
		{
			label: "Contact Us",
			link: WebRoutes.CONTACT_US,
		},
	];

	const items = _MENU.map((link) => {
		const menuItems = link.links?.map((item) => (
			<Link to={item.link} onClick={() => setOpened(false)}>
				<div className="navbar-inner-item">{item.label}</div>
			</Link>
		));

		if (menuItems) {
			return (
				<>
					<div className="navbar-item">{link.label}</div>
					<div className="navbar-item-links">{menuItems}</div>
				</>
			);
		}

		return (
			<Link to={link.link} onClick={() => setOpened(false)}>
				<div className="navbar-item">{link.label}</div>
			</Link>
		);
	});

	return (
		<Drawer id="mobileNavbar" opened={opened} onClose={() => setOpened(false)} padding="xl" size="xl">
			<div id="mobileNavbar">{items}</div>
		</Drawer>
	);
}

export default MobileNavbar;
