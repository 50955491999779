import { useEffect, useState } from "react";
import { Grid, Modal } from "@mantine/core";
import supabase from "../../utils/Supabase";

/// <summary>
/// Author: JavaChips
/// </summary>
function PastFestivals() {
	const [festivals, setFestivals] = useState([]);

	useEffect(() => {
		document.title = `#GTLF2023 | Past Festivals`;

		(async () => {
			const { data, error } = await supabase.from("past-festivals").select("*").eq("is_deleted", false).order("created_at", { ascending: false });

			if (error !== null) {
				throw error.message;
			}

			setFestivals(data);
		})();
	}, []);

	return (
		<div id="pastFestivals">
			<div className="title">
				<h3>Past Festivals</h3>
			</div>
			<Grid gutter={30}>
				{festivals.map((item) => (
					<Grid.Col xs={12} md={4} lg={4}>
						<div className="festival-item">
							<a href={item.link} target="_blank">
								<img src={item.image} alt="Past Festival" />
							</a>
						</div>
					</Grid.Col>
				))}
			</Grid>
		</div>
	);
}

export default PastFestivals;
