import { useEffect } from "react";
import { Grid, TextInput, Button, Group, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";

/// <summary>
/// Author: JavaChips
/// </summary>
function ContactUs() {
	const form = useForm({
		initialValues: {
			firstName: "",
			lastName: "",
			contact: "",
			email: "",
			message: "",
		},
		validate: {
			// email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
		},
	});

	const onSubmit = async (values) => {
		try {
			var data = {
				service_id: "service_e0nxv9q",
				template_id: "template_sa7twdr",
				user_id: "4QWIg1aGSA9wTWVUd",
				template_params: {
					from_name: values?.firstName + " " + values?.lastName,
					from_contact: values?.contact,
					message: values?.message,
					from_email: values?.email,
				},
			};

			let responseJson = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (responseJson.status !== 200) {
				throw "err";
			}

			showNotification({
				title: "Success",
				message: "Successfully sent your message! We will get back to you through your email/contact :)",
			});

			form.reset();
		} catch (err) {
			showNotification({
				color: "red",
				title: "Oops",
				message: "Failed to send your details. Please try again.",
			});
		}
	};

	return (
		<div id="contactUs">
			<div className="title">
				<h3>Contact Us</h3>
			</div>
			<Grid gutter={40}>
				<Grid.Col span={12}>
					<form onSubmit={form.onSubmit(onSubmit)}>
						<Grid gutter={30}>
							<Grid.Col span={6}>
								<TextInput required label="First Name" {...form.getInputProps("firstName")} />
							</Grid.Col>
							<Grid.Col span={6}>
								<TextInput required label="Last Name" {...form.getInputProps("lastName")} />
							</Grid.Col>
							<Grid.Col span={12}>
								<TextInput required label="Contact" {...form.getInputProps("contact")} />
							</Grid.Col>
							<Grid.Col span={12}>
								<TextInput required label="Email" {...form.getInputProps("email")} />
							</Grid.Col>
							<Grid.Col span={12}>
								<Textarea label="Comment or Message" {...form.getInputProps("message")} required />
							</Grid.Col>
						</Grid>

						<Group position="right" mt="md">
							<Button type="submit">Submit</Button>
						</Group>
					</form>
				</Grid.Col>
			</Grid>
		</div>
	);
}

export default ContactUs;
