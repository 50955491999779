import { useState } from "react";
import { Link } from "react-router-dom";
import WebRoutes from "../utils/WebRoutes";
import Navbar from "./Navbar";
import { Burger, Grid } from "@mantine/core";
import MobileNavbar from "./MobileNavbar";

/// <summary>
/// Author: JavaChips
/// </summary>
function Header() {
	const [isOpened, setIsOpened] = useState(false);

	return (
		<>
			<header className="header">
				<Link to={WebRoutes.HOME}>
					<img className="logo" src={require("../assets/images/gtlf-logo.png")} alt="GTLF Logo" />
				</Link>
				<Navbar year="2022" />
			</header>
			<header className="header-mobile">
				<Grid align="center">
					<Grid.Col span={2}>
						<Burger size="sm" opened={isOpened} onClick={() => setIsOpened((o) => !o)} />
					</Grid.Col>
					<Grid.Col span={8}>
						<Link to={WebRoutes.HOME}>
							<h3>GTLF</h3>
						</Link>
					</Grid.Col>
					<Grid.Col span={2}></Grid.Col>
				</Grid>

				<MobileNavbar year="2022" opened={isOpened} setOpened={setIsOpened} />
			</header>
		</>
	);
}

export default Header;
